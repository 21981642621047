<template>
  <div class="router-wrap">
    <div class="router-content">
      <div class="router-title">
        <div class="title-left" ref="title">
          <p>资讯中心</p>
          <img src="@/assets/imgs/news/news-title.png" alt="">
        </div>
        <div class="title-button">
          <router-link class="diy-btn" tag="div" to="/NewsDetails">
            <span v-if="!isEn">查看更多</span>
            <span v-if="isEn">View More</span>
             <img src="@/assets/imgs/common/back.png" alt="">
          </router-link>
        </div>

      </div>
      <ul class="new-list" ref="items">
        <li v-for="(val,ind) in newsList" @click="toDetails(val)">
          <div class="new-left">
            <p><img :src="val.img" alt=""></p>

          </div>
          <div class="new-right">
            <p class="new-title text-overflow">{{val.title}}</p>
            <div class="new-sub-title text-overflow-3">{{val.subTitle}}</div>
            <div class="new-date">{{val.date}}</div>
          </div>
        </li>
      </ul>
      <div class="router-text">
        <img src="@/assets/imgs/news/news-text.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import { selectNewsList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";

export default {
  name: 'News',
  props:{
    activeIndV:[Number],
  },
  data(){
    return {
      isEn: sessionStorage.getItem("langType") === 'en-us' ? true : false,
      newsList:[
      //     {
      //   img:require("@/assets/imgs/news/news1.png"),
      //   date:"2021-04-05",
      //   title:"拥抱更美好的的未来，康存集团全新官网",
      //   subTitle:"拥抱更美好的的未来，康存集团全新企业宣传片正式发布，拥抱更美好的的未来，康存集团全新企业宣传片正式发布，"
      // },{
      //   img:require("@/assets/imgs/news/news2.png"),
      //   date:"2021-04-05",
      //   title:"拥抱更美好的的未来，康存集团全新官网",
      //   subTitle:"拥抱更美好的的未来，康存集团全新企业宣传片正式发布，拥抱更美好的的未来，康存集团全新企业宣传片正式发布，"
      // },{
      //   img:require("@/assets/imgs/news/news3.png"),
      //   date:"2021-04-05",
      //   title:"拥抱更美好的的未来，康存集团全新官网",
      //   subTitle:"拥抱更美好的的未来，康存集团全新企业宣传片正式发布，拥抱更美好的的未来，康存集团全新企业宣传片正式发布，"
      // },{
      //   img:require("@/assets/imgs/news/news4.png"),
      //   date:"2021-04-05",
      //   title:"拥抱更美好的的未来，康存集团全新官网",
      //   subTitle:"拥抱更美好的的未来，康存集团全新企业宣传片正式发布，拥抱更美好的的未来，康存集团全新企业宣传片正式发布，"
      // }
      ]
    }
  },
  watch: {
    activeIndV:function(val){
      //console.log(this.activeIndV)
      if(val== 4){
        this.$refs.title.className = "title-left translate"
        this.$refs.items.className = "new-list translate"
      }else{
        this.$refs.title.className = "title-left"
        this.$refs.items.className = "new-list"
      }
    },
  },
  components: {
  },
  created() {
    this.getselectNewsList()
  },
  methods:{
    getselectNewsList() {
      selectNewsList({
        lang: sessionStorage.getItem("langType"),
        // newsType: this.activeTab,
      }).then((res) => {
        this.newsList = [];
        let data = [];

        res.rows.map((v, i) => {
          if(i <= 3){
            data.push({
              img: baseURL + v.coverImage,
              date: v.publishTime,
              title: v.title,
              subTitle: v.subTitle,
              id: v.id,
            });
          }

        });
        this.newsList = data;
      });
    },
     toDetails(val) {
      console.log(val, 123);
      sessionStorage.setItem("newsItemDetailsId", val.id);
      this.$router.push("/NewsItemDetails");
    },
  }
}
</script>
<style scoped lang="scss">
.router-wrap{
  height: 100%;
  background: url(../assets/imgs/common/index-bg.jpg) center center;
  overflow: hidden;
  .router-content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 180px;
    color: #fff;
    .router-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        transform: translateX(-500px);
        &.translate{
          transition: all 0.3s ease-in-out 0.3s;
          transform: translateX(0px);
        }
        &>p{
          line-height: 1;
          font-size: 40px;
          margin-bottom: 26px;
        }
      }
      .title-button{
        display: flex;
        .diy-btn{
          width: 132px;
          height: 44px;
          border: 1px solid #fff;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 36px;
          &>img{
            margin-left: 14px;
          }
        }
      }
    }
    .new-list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 50px;
      min-height: 508px;
      & li{
        transform: scale(0,0);
        cursor: pointer;
      }
      &.translate li{
        transform: scale(1,1);
        &:nth-child(1){
          transition: all 0.3s ease-in-out 0.35s;
        }
        &:nth-child(2){
          transition: all 0.3s ease-in-out 0.4s;
        }
        &:nth-child(3){
          transition: all 0.3s ease-in-out 0.45s;
        }
        &:nth-child(4){
          transition: all 0.3s ease-in-out 0.5s;
        }
      }
      li{
        width: 560px;
        height: 162px;
        background: rgba(0,114,203,0.7);
        margin-bottom: 67px;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        .new-left{
          width: 230px;
          &>p{
            width: 100%;
            margin-left: -20px;
            margin-top: -25px;
            height: 100%;
            overflow: hidden;
            & img{
              width: 100%;
              transition: transform 0.75s ease;
              &:hover{
                transition: transform 0.75s ease;
                transform: scale(1.2,1.2);
              }
            }
          }

        }
        .new-right{
          flex: 1;
          padding: 20px 30px 20px 0;
          width: 330px;
          .new-title{
            font-size: 16px;
            margin-bottom: 5px;
          }
          .new-sub-title{
            height: 66px;
             font-size: 14px;
             opacity: 0.6;
          }
          .new-date{
            margin-top: 8px;
            font-size: 12px;
            opacity: 0.6;
            text-align: right;
            color: #f6f6f6;
          }
        }
      }

    }
    .router-text{
      margin-top: -47px;
      text-align: right;
    }
  }
}
</style>
